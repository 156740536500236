<template>
  <v-container>
    <my-toolbar-progress-bar
      :isLoading="isLoading"
      :color="toolbareColor"
      slot="extension"
    />
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Søk"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="dataList"
      class="elevation-1"
      @click:row="rowClick"
      item-key="AktuellForTypeID"
      single-select
      :search="search"
    >
      <template #top>
        <v-toolbar flat color="white">
          <v-toolbar-title
            >Aktuell for type - {{ currentHeading }}</v-toolbar-title
          >
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <template #activator="{ on }">
              <v-btn color="primary" dark class="mb-2" v-on="on">Ny</v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <dlgAktuellForType
                      v-if="dialog"
                      v-model="editedItem"
                      :editMode="editedIndex"
                    />
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">Avbryt</v-btn>
                <v-btn color="blue darken-1" text @click="save">Lagre</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template #item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
      <template #no-data>
        <v-btn color="primary" @click="initialize">Reset</v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import axios from "axios";
import { DataSourceURL } from "../global/variables";
import KeyValue from "../global/KeyValue";
import MyToolbarProgressBar from "@/components/MyToolbarProgressBar.vue";

export default {
  extends: KeyValue,
  components: {
    dlgAktuellForType: () => import("../dialog/dlgAktuellForType"),
    MyToolbarProgressBar,
  },
  data() {
    return {
      search: "",
      isLoading: false,
      toolbareColor: "red",
      dialog: false,
      headers: [
        { text: "Beskrivelse", value: "Beskrivelse" },
        { text: "Aksjon", value: "actions", sortable: false },
      ],
      currentAktuellForTypeID: -1,
      currentHeading: "",
      dataList: [],
      editedIndex: -1,
      editedItem: {
        AktuellForTypeID: "",
        Beskrivelse: "",
      },
      defaultItem: {
        AktuellForTypeID: "",
        Beskrivelse: "",
      },
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Ny" : "Endre";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    rowClick: function (item, row) {
      row.select(true);
      //this.editedIndex = this.dataList.indexOf(item);
      this.currentAktuellForTypeID = item.AktuellForTypeID;
      this.currentHeading = item.Beskrivelse;
      this.$root.$emit(
        "AktuellForType",
        item.AktuellForTypeID,
        item.Beskrivelse
      );
    },
    initEditItem() {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    },
    initialize() {
      this.isLoading = true;
      this.GetData();
      this.initEditItem();
      this.isLoading = false;
    },
    GetAktuellForType(AktuellForTypeID) {
      axios({
        method: "get",
        params: { AktuellForTypeID: AktuellForTypeID },
        url: DataSourceURL + "/AktuellForType/GetAktuellForType",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Methods": "GET",
        },
      })
        .then((res) => {
          if (!res.data.ErrorStatus) {
            this.editedItem = res.data.DataList;
            this.dialog = true;
          } else {
            alert(res.data.FeedbackText);
          }
        })
        .catch((err) => {
          alert(
            `There was an error in reading /AktuellForType/GetAktuellForType. See details: ${err}`
          );
        });
    },

    GetData() {
      axios({
        method: "get",
        url: DataSourceURL + "/AktuellForType/GetData",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Methods": "GET",
        },
      })
        .then((res) => {
          this.isLoading = false;
          if (!res.data.ErrorStatus) {
            this.dataList = res.data.DataList;
          } else {
            alert(res.data.FeedbackText);
          }
        })
        .catch((err) => {
          this.isLoading = false;
          alert(
            `There was an error in reading /AktuellForType/GetData. See details: ${err}`
          );
        });
    },

    editItem(item) {
      this.editedIndex = this.dataList.indexOf(item);
      this.GetAktuellForType(item.AktuellForTypeID);
      //this.editedItem = Object.assign({}, item)
      //this.dialog = true
    },

    DeleteData(index, AktuellForTypeID) {
      axios({
        method: "delete",
        url: DataSourceURL + "/AktuellForType/DoDelete",
        params: { AktuellForTypeID: AktuellForTypeID },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Methods": "DELETE",
        },
      })
        .then((res) => {
          if (!res.data.ErrorStatus) {
            this.dataList.splice(index, 1);
          } else {
            alert(res.data.FeedbackText);
          }
        })
        .catch((err) => {
          alert(
            `There was an error in reading /AktuellForType/Delete. See details: ${err}`
          );
        });
    },

    deleteItem(item) {
      const index = this.dataList.indexOf(item);
      if (
        confirm(
          "Are you sure you want to delete this item? " + item.Beskrivelse
        ) == true
      ) {
        this.DeleteData(index, item.AktuellForTypeID);
      }
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.initEditItem();
      });
    },

    DoSave() {
      let submit = true;

      submit = true;
      let myUrl;
      if (this.editedIndex > -1) {
        myUrl = DataSourceURL + "/AktuellForType/DoSaveUpdate";
      } else {
        myUrl = DataSourceURL + "/AktuellForType/DoSaveNew";
      }

      if (submit) {
        axios({
          method: "post",
          url: myUrl,
          data: { data: this.editedItem },
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-token"),
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Content-Type",
            "Access-Control-Allow-Methods": "POST",
          },
        })
          .then((res) => {
            if (!res.data.ErrorStatus) {
              //Save OK

              if (this.editedIndex > -1) {
                Object.assign(this.dataList[this.editedIndex], this.editedItem); //Oppdatering
              } else {
                this.editedItem.AktuellForTypeID = res.data.ID;
                this.dataList.push(this.editedItem); //Ny
              }
              this.close();
            } else {
              alert(res.data.FeedbackText);
            }
          })
          .catch((err) => {
            alert(`There was an error saveing your form. See details: ${err}`);
          });
      }
    },

    save() {
      this.DoSave();
    },
  },
};
</script>
